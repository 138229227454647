import { render, staticRenderFns } from "./table-dropdown-path.vue?vue&type=template&id=4bc1cfe3&scoped=true&"
import script from "./table-dropdown-path.vue?vue&type=script&lang=ts&"
export * from "./table-dropdown-path.vue?vue&type=script&lang=ts&"
import style0 from "./table-dropdown-path.vue?vue&type=style&index=0&id=4bc1cfe3&lang=less&scoped=true&"
import style1 from "./table-dropdown-path.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc1cfe3",
  null
  
)

export default component.exports