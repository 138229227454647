import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let TableDropDownPath = class TableDropDownPath extends Vue {
    get isShowOverlay() {
        return this.path?.includes('/');
    }
};
__decorate([
    Prop()
], TableDropDownPath.prototype, "deptName", void 0);
__decorate([
    Prop()
], TableDropDownPath.prototype, "path", void 0);
TableDropDownPath = __decorate([
    Component({
        name: 'TableDropDownPath',
    })
], TableDropDownPath);
export default TableDropDownPath;
